.container {
	padding-bottom: 40px;
	background-color: #fefefe;
}

@media (min-width: 900px) {
	.container {
		display: flex;
	}
}
