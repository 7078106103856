body {
	margin: 0;
	padding: 0;
}

.banner {
	position: relative;
	margin: 0;
	margin-top: 64px;
	width: 100%;
	height: 85vh;
	padding: 0;
	text-align: center;
	box-sizing: border-box;
}

.fallbackImage {
	width: 100%;
	height: inherit;
}

.overlay {
	position: absolute;
	z-index: 1;
	background: linear-gradient(to bottom, rgb(52, 168, 83, 0.8), rgba(0, 96, 0, 0.6));
	height: inherit;
	width: inherit;
	box-sizing: border-box;
	top: 0;
}

.banner_title {
	z-index: 2;
	color: #fff;
	font-size: 40px;
	width: 100%;
	padding: 0 20px;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	position: absolute;
	top: 16%;
	box-sizing: border-box;
}

.summary {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #fff;
	z-index: 2;
	width: 80%;
	max-width: 400px;
	margin: 20px auto;
	position: absolute;
	top: calc(20% + 200px);
	left: 10%;
	right: 10%;
}

.cta {
	z-index: 3;
	position: relative;
	top: -18%;
	border: none;
	border-radius: 4px;
	padding: 15px 25px;
	font-weight: 600;
	font-size: 14px;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	background-color: #fff;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
	transition: transform 0.3s ease-out;
}

.cta a {
	color: #006600;
	text-decoration: none;
}

.cta:hover {
	cursor: pointer;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
	transform: scale(1.03);
}

.cta:focus,
.cta:active {
	transform: scale(0.9);
	outline: #65b037;
}

@media (min-width: 900px) {
	.banner_title {
		font-size: 60px;
		display: flex;
		justify-content: center;
	}
	.fallbackImage {
		display: none;
	}
	.summary {
		font-size: 20px;
	}
	.cta {
		position: absolute;
		top: 80%;
		left: 45%;
		padding: 20px 30px;
	}
}
