.section {
	margin: 0;
	background-color: rgba(231, 255, 230, 0.1);
	padding: 60px 10px 40px 10px;
	border-top: 1px solid rgba(68, 118, 37, 0.3);  /* Change color and thickness as needed */
}

.section h1 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	color: #447625;
	font-size: 2.5em;
	width: 100%;
	text-align: center;
}

.members {
	margin: 0 auto;
	margin-top: 70px;
	width: 94%;
	max-width: 70%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
}

.each {
	display: flex;
	flex-direction: column;
	margin: 0 10px 30px 10px;
	width: 40%;
	max-width: 200px;
	text-align: center;
	
	
}

.each img {
	height: 100px;
	width: 100px;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	margin: 10px auto;
}

.each span.name {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-weight: 600;
	color: #030418;
	margin-top: 10px;
}

.each span.qualification {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #4b4b4e;
	margin-top: 7px;
}

.each a {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	color: #046601;
	font-weight: 600;
	margin-top: 10px;
	text-decoration: none;
}

@media (min-width: 600px) {
	.each img {
		width: 150px;
		height: 150px;
	}
}

.title {
	font-weight: normal; /* Make only "CEO" not bold */
  }