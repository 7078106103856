.main {
	padding: 0;
	padding-top: 64px;
	padding-bottom: 40px;
	margin: 0;
	background-color: #fefefe;
}

.main h1,
.main h2,
.main h3 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	margin-top: 30px;
	color: #447625;
	font-size: 28px;
	width: 100%;
	text-align: center;
}

.main p {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #4b4b4e;
	line-height: 2em;
	font-weight: 500;
	width: 90%;
	max-width: 600px;
	margin: 20px auto;
}
