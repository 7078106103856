.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	left: 0;
	position: fixed;
	padding: 0;
	padding-right: 10px;
	height: 64px;
	width: 100vw;
	background-color: #fff;
	opacity: 1;
	z-index: 4;
	box-shadow: 0 2px 1px rgba(78, 78, 75, 0.2);
	box-sizing: border-box;
}

.nav {
	display: none;
}

.HamStyle {
	width: 25px;
	margin: 17px 16px;
	padding: 0;
}

.hamBarStyle {
	width: 100%;
	background-color: #006600;
	height: 3px;
	margin: 3px 0;
	border-radius: 1.5px;
}

@media (min-width: 900px) {
	.header {
		padding-left: 20px;
		padding-right: 40px;
		justify-content: space-between;
	}
	.nav {
		display: flex;
		width: 30%;
		height: inherit;
		margin: 0;
	}
	.HamStyle {
		display: none;
	}
}
