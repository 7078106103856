.service_detail {
	margin-top: 114px;
	padding-top: 1px;
}

.service_detail h2,
.service_detail h3 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	text-align: center;
	margin-top: 50px;
	color: #006600;
}

.service_detail p {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-weight: 500;
	font-size: 18px;
	color: #4b4b4e;
	line-height: 2em;
	width: 90%;
	max-width: 600px;
	margin: 30px auto;
}

.service_detail ul,
.service_detail ol {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	color: #4b4b4e;
	width: 80%;
	max-width: 400px;
	margin: 25px auto;
}

.service_detail ul li,
.service_detail ol li {
	margin-top: 30px;
}

@media (min-width: 900px) {
	.service_detail {
		margin-top: 64px;
		margin-left: 50px;
	}
}
