.rightSideDrawer {
	height: 100vh;
	width: 300px;
	position: fixed;
	top: 0;
	right: -300px;
	padding-top: 40px;
	padding-right: 170px;
	z-index: 1000;
	overflow-x: auto;
	overflow-y: auto;
	box-sizing: border-box;
	background-color: #fffffe;
	box-shadow: -10px 0 50px rgba(0, 0, 0, 0.1);
	opacity: 1;
	transition: transform 0.4s ease-out;
}

.rightSideDrawerClose {
	transform: translateX(100%);
}

.rightSideDrawerOpen {
	transform: translateX(-100%);
}
