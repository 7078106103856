.NavigationItem {
	height: 100%;
	margin: 0;
	margin-left: 10px;
	box-sizing: border-box;
	list-style: none;
}

.NavigationItem a {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 600;
	margin: 0;
	padding: 13px;
	color: #34a853;
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	outline: none;
}

.NavigationItem a:hover {
	border-bottom: 3px solid #34a853;
}

.Active {
	border-bottom: 3px solid #34a853;
}

@media (min-width: 900px) {
	.NavigationItem {
		margin: 0;
		flex: auto;
	}
	.NavigationItem a {
		margin: auto;
	}
}
