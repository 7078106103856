.section {
	margin: 0;
	background-color: #fefefe;
	padding: 90px 10px 40px 10px;
}

.section h1 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	color: #447625;
	font-size: 2.5em;
	width: 100%;
	text-align: center;
}

.section p {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #4b4b4e;
	line-height: 2em;
	font-weight: 500;
	width: 90%;
	max-width: 600px;
	margin: 20px auto;
}
