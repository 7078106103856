.main {
	margin: 0;
	text-align: center;
	background-color: #fefefe;
	padding: 90px 10px 40px 10px;
}

.each {
	border: 1px solid #dadce0;
	border-radius: 5px;
	padding: 10px;
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 400px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.main h1 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	color: #447625;
	margin-bottom: 30px;
}

.questions {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	font-size: 20px;
	font-weight: 800;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #030418;
	text-align: left;
}

.main hr {
	background-color: #447625;
	width: 100%;
	height: 1px;
}

.answer {
	font-family: "Muli";
	font-size: 17px;
	font-weight: 500;
	text-align: left;
	line-height: 1.5em;
}

@media (min-width: 900px) {
	.each {
		padding: 10px 20px;
	}
}
