.section {
	margin: 0;
	text-align: center;
	padding: 70px 0;
	background-color: #171728;
	color: #fff;
	box-sizing: border-box;
}

.section .sub_title {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	font-size: 17px;
	font-weight: 500;
}

.section h2 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	font-size: 34px;
}

.section p.main {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	line-height: 1.5em;
	width: 92%;
	margin: 40px auto;
}

.section button.cta {
	border: none;
	border-radius: 4px;
	padding: 15px 25px;
	color: #fff;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	font-weight: 500;
	font-size: 14px;
	background-color: #65b037;
	transition: transform 0.3s ease-out;
}

.cta:hover {
	cursor: pointer;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
	transform: scale(1.03);
}

.cta:focus,
.cta:active {
	transform: scale(0.9);
	outline: #65b037;
}

@media (min-width: 900px) {
	.section {
		height: calc(100vh - 64px);
		padding-top: 120px;
	}
	.section h2 {
		font-size: 38px;
	}
	.section p.main {
		max-width: 600px;
		font-size: 18px;
		margin: 0 auto;
		margin-bottom: 50px;
	}
}
