.Ul_horizontal {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.Ul_vertical {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0;
	padding: 0;
}

@media (min-width: 900px) {
	.Ul_horizontal {
		justify-content: center;
	}
}
