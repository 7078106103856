.footer {
	background: linear-gradient(to bottom, #65b037, #447625);
	text-align: center;
	color: #fff;
	padding: 50px 0;
	padding-bottom: 20px;
}

.social {
	width: inherit;
	margin-bottom: 20px;
}

.social a {
	margin: 5px;
	text-decoration: none;
}

.social a i {
	color: #447625;
	font-size: 18px;
	background-color: #f6f6f6;
	height: 35px;
	width: 35px;
	border-radius: 50%;
	padding-top: 7px;
	box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	transition: transform 0.5s ease-in-out;
}

.social a i:hover {
	transform: translateY(-5px);
}

.footer div.copyright {
	margin-bottom: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: "Muli", Arial, Helvetica, sans-serif;
}

.footer p {
	font-family: "Muli", Arial, Helvetica, sans-serif;
	width: 90%;
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
}

.footer b {
	font-family: "MOntserrat", Arial, Helvetica, sans-serif;
}

.footer p a {
	color: #fff;
	line-height: 1.6em;
	text-decoration: none;
}
