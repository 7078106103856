.section {
	margin: 0;
	padding: 90px 10px;
	padding-bottom: 60px;
	text-align: center;
	width: 100%;
	height: calc(100vh - 64px);
	box-sizing: border-box;
	background-color: #fafafa;
}

.section h2.h2 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	color: #006600;
	font-size: 28px;
	margin-top: 30px;
}

.section p.p {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	font-weight: 500;
	color: #626675;
	line-height: 2em;
	margin: 30px auto;
	max-width: 400px;
}

.section p.p .audit {
	color: #030418;
	font-size: 18px;
	font-weight: 600;
}

.section p.p .contact {
	display: block;
	margin: 10px 0;
	color: #65b037;
	font-weight: 600;
}

@media (min-width: 900px) {
	.section h2.h2 {
		font-size: 32px;
		margin-top: 50px;
	}
	.section p.p {
		max-width: 600px;
		font-size: 18px;
		margin-bottom: 45px;
	}
}
