.horiNav {
	display: flex;
	width: 100%;
	height: 50px;
	overflow-x: auto;
	top: 64px;
	position: fixed;
	box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
	opacity: 1;
	background-color: #fefefe;
	box-sizing: border-box;
}
.aside {
	display: none;
}

.horiNav li {
    border-bottom: 2px solid black; /* Change color as needed */
    padding-bottom: 5px; /* Adjust spacing */
}

@media (min-width: 900px) {
	.horiNav {
		display: none;
	}
	.aside {
		display: block;
		margin-top: 20vh;
		height: 100vh;
		width: 30%;
		max-width: 300px;
	}
	.vertNav {
		position: fixed;
		left: 20px;
	}
}
