.dots {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #cdd;
	border: none;
	margin: 2px;
}

.ellipsesHorizontal {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 6px 0;
}

.ellipsesVertical {
	cursor: pointer;
	padding: 0 6px;
}