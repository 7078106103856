.supportedBySection {
	text-align: center;
	padding: 40px 20px;
	background-color: #ffffff;
  }
  
  .supportedBySection h2 {
	font-size: 24px;
	color: #333;
	margin-bottom: 20px;
  }
  
  .logosContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 20px;
  }
  
  .logoItem {
	width: 150px;
	height: auto;
	padding: 10px;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .logoItem img {
	max-width: 100%;
	height: auto;
	object-fit: contain;
  }


  .supportedBySection h2 {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
	color: #006600;
	font-size: 38px;
	margin-top: 30px;
	margin-bottom: 60px; /* Adjust spacing */
  }